<template>
    <BaseLayout>
        <div class="break-24 retail-dekstop"></div>
        <section class="ol-home-category">
            <div class="ol-wrapper ol-wrapper--large">
                <div class="retail-dekstop">
                    <img src="https://asset.morefurniture.id/MORE/retail/Banner-Facade-More-1920x800.jpg" width="100%" style="border-radius: 8px;">
                    <div style="position: absolute; margin-top: -120px;margin-left: 81px;">
                        <a target="_blank" class="ol-btn ol-btn-wishlist" style="margin-right: 8px;font-size:20px;" href="https://www.google.com/maps/place/MORE+Furniture+Retail+Experience,+Jl.+Mayjen+HR.+Muhammad+No.33,+Pradahkalikendal,+Kec.+Dukuhpakis,+Surabaya,+Jawa+Timur+60226/@-7.2849797,112.6957791,22z/data=!4m6!3m5!1s0x2dd7fd127a8f7b81:0xf5d98ce7797aef6f!8m2!3d-7.2849594!4d112.6957295!16s%2Fg%2F11ld8f0h0p">Menuju Lokasi</a>
                        <a target="_blank" class="ol-btn ol-btn-wishlist"  style="font-size:20px;" href="https://api.whatsapp.com/send/?phone=+62812-3024-2365&text=Hai+MORE%2C+saya+ingin+bertanya+More+Retail+Experience+&app_absent=0">Whatsapp</a>
                        
                    </div>
                </div>
                <div class="retail-mobile">
                    <img src="https://asset.morefurniture.id/MORE/retail/Banner-Facade-More-600x350.jpg" width="100%" style="border-radius: 8px;">
                    <div style="position: absolute; margin-top: -61px;margin-left: 21px;">
                        <a target="_blank" class="ol-btn ol-btn-wishlist" style="margin-right: 8px;" href="https://www.google.com/maps/place/MORE+Furniture+Retail+Experience,+Jl.+Mayjen+HR.+Muhammad+No.33,+Pradahkalikendal,+Kec.+Dukuhpakis,+Surabaya,+Jawa+Timur+60226/@-7.2849797,112.6957791,22z/data=!4m6!3m5!1s0x2dd7fd127a8f7b81:0xf5d98ce7797aef6f!8m2!3d-7.2849594!4d112.6957295!16s%2Fg%2F11ld8f0h0p">Menuju Lokasi</a>
                        <a target="_blank" class="ol-btn ol-btn-wishlist" href="https://api.whatsapp.com/send/?phone=+62812-3024-2365&text=Hai+MORE%2C+saya+ingin+bertanya+More+Retail+Experience+&app_absent=0">Whatsapp</a>
                    </div>
                </div>
                <br>
                
                <img src="https://asset.morefurniture.id/MORE/promo-retail/Brosur-Web1.jpg" width="100%"><br>
                <img src="https://asset.morefurniture.id/MORE/promo-retail/Brosur-Web2.jpg" width="100%"><br>
                <!-- <img src="https://asset.morefurniture.id/MORE/promo-retail/3.jpeg" width="100%"><br> -->
                <img src="https://asset.morefurniture.id/MORE/promo-retail/1.jpeg" width="100%"><br>
                <img src="https://asset.morefurniture.id/MORE/promo-retail/2.jpeg" width="100%"><br>
            </div>
        </section>

        
        
    </BaseLayout>
</template>

<script>

import BaseLayout from '@/components/landing/BaseLayout.vue';
import Categories from "@/components/product/Categories.vue";
import BannerTop from "@/components/home/BannerTop.vue";
import BannerBottom from "@/components/home/BannerBottom.vue";
import Inspirasi from "@/components/home/InspirasiV2.vue";
import Endorsement from "@/components/home/Endorsement.vue";
import Carousel from "component-vue-efi/src/components/carousel/SlideCarousel.vue";
import CarouselLoader from "@/components/loading/CarouselLoader";
import ProductList from "@/components/product/ProductList.vue";
import ProductListGroup from "@/components/product/ProductListGroup.vue";
import ProductFlash from "@/components/product/ProductFlash.vue"
import Flashsale from "@/components/product/Flashsale.vue";
import FlashsaleGroup from '@/components/product/FlashsaleGroup';
import ProductLoader from '@/components/loading/product/Content.vue';
import ProductListBox from "@/components/product/ProductListBox.vue";
import TopProductLoader from "@/components/loading/product/Top";
import BannerBottomLoading from "@/components/loading/BannerBottomLoading";
import CarouselProduct from '@/components/home/CarouselProduct';
import {mapActions,mapGetters} from "vuex";

export default {
    name: 'RetailExperince',
    components:{
        ProductFlash,
        BaseLayout,
        Categories,
        ProductListBox,
        ProductListGroup,
        ProductList,
        ProductLoader,
        Carousel,
        CarouselLoader,
        TopProductLoader,
        BannerTop,
        BannerBottom,
        BannerBottomLoading,
        Inspirasi,
        Endorsement,
        FlashsaleGroup,
        CarouselProduct
    },
    data(){
        return {
            categories:[],
            modalShow: false,
            modalLink: null,
            modalImg:null,
            loading:false,
            loadingBottom:false,
            loadingTopProduct:false,
            loadingLastSeen:true,
        }
    },
    computed:{
        ...mapGetters("product/list",["products","topProducts","categoriesGroup"]),
        ...mapGetters("home",["bannerTop","popup","produtTerbaik","productGroups"]),
        ...mapGetters('auth', ['authenticated']),
        ...mapGetters('profile/product', ['lastSeen']),
    },
    methods:{
        ...mapActions('profile/product', ['saveProductHistory','fetchLastSeen']),
        ...mapActions("home",["fetchProductGroups", "getBanner","getPopup","getProdutTerbaik"]),
        ...mapActions("product/list",["fetchProducts","fetchTopProducts","getCategoriesGroup"]),

    },
}
</script>

<style scoped lang="css">
.ol-home-menu{
    padding-top:24px;
}

@media (max-width: 768px) {
    .ol-home-menu{
        padding-top:0px;
    }
}

.ol-btn-wishlist{
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.retail-dekstop{
    display:block;
}

.retail-mobile{
    display:none;
}

@media(max-width:767px){
    .retail-dekstop{
        display:none;
    }

    .retail-mobile{
        display:block;
    }
}
</style>